import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import Link from 'components/Link';
import {
    RetrieveMarketingFeaturedVenuesDocument,
    RetrieveMarketingFeaturedVenuesQuery,
} from 'gql/generated';
import {getS3ImageURL} from 'utils/environment';

type FeatureVenueCardProps = {
    imgUrl: string;
    isExternal: boolean;
    link: string;
    name: string;
};

const FeaturedVenueCard: FC<FeatureVenueCardProps> = ({
    imgUrl,
    isExternal,
    link,
    name,
}) => {
    const {t} = useTranslation();

    return (
        <Link
            aria-label={name}
            target={isExternal ? '_blank' : undefined}
            to={link}
        >
            <figure className="bg-step-dark-only link-item relative flex w-full select-none flex-col overflow-hidden rounded">
                <div className="w-full pb-[56.5%]">
                    <picture>
                        <img
                            alt={t('imageCarousel.imageName', {name})}
                            className="absolute left-0 top-0 h-full w-full overflow-hidden rounded-md object-cover object-center"
                            loading="lazy"
                            src={imgUrl}
                        />
                    </picture>
                </div>
            </figure>
            <div className="text-grey-200">{name}</div>
        </Link>
    );
};

export const FeaturedVenueCards: FC = () => {
    const {data} = useQuery<RetrieveMarketingFeaturedVenuesQuery>(
        RetrieveMarketingFeaturedVenuesDocument
    );

    const fallbackData = {
        imageUrl: getS3ImageURL('venue_fallback', 'png'),
        isExternal: false,
        link: 'https://pocket-concierge.jp/',
    };

    const marketingContent = data?.retrieveMarketingFeaturedVenues;

    return (
        <div className="-mb-12 bg-grey-800 pb-12 pt-8">
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {!marketingContent &&
                    Array(12)
                        .fill(undefined)
                        .map((_, index) => (
                            <FeaturedVenueCard
                                key={index}
                                imgUrl={fallbackData.imageUrl}
                                isExternal={fallbackData.isExternal}
                                link={fallbackData.link}
                                name="Venue"
                            />
                        ))}
                {marketingContent &&
                    marketingContent.map((item) => (
                        <FeaturedVenueCard
                            key={item.id}
                            imgUrl={item.imageUrl}
                            isExternal={item.external}
                            link={item.link}
                            name={item.restaurant?.name || 'Venue'}
                        />
                    ))}
            </div>
        </div>
    );
};
