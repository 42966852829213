import {FC} from 'react';
import {useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import VenueResultCard from 'components/Venue/VenueResultCard';
import {
    RetrieveMarketingEditorsPicksDocument,
    RetrieveMarketingEditorsPicksQuery,
    VenueSearchResultFragment,
} from 'gql/generated';

export const EditorsPickCards: FC = () => {
    const {t} = useTranslation();

    const {data} = useQuery<RetrieveMarketingEditorsPicksQuery>(
        RetrieveMarketingEditorsPicksDocument
    );

    const marketingContent = data?.retrieveMarketingEditorsPicks?.filter(
        (content) => {
            return content.restaurant !== null;
        }
    );

    return (
        <div>
            <div className="sm:site-container bg-body sticky inset-x-0 top-[3.25rem] z-40 flex items-start justify-between px-4 py-1 sm:static">
                <div>
                    <h3 className="text-xl">{t('home.editorsPicks')}</h3>
                </div>
            </div>
            <div className="site-container mt-4 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4 lg:gap-2 2xl:gap-4">
                {marketingContent &&
                    marketingContent.map((content) => (
                        <VenueResultCard
                            key={content.id}
                            dataDataDogActionName={content.imageFilename}
                            fullUrl={content.link}
                            minimal={true}
                            venue={
                                content.restaurant as VenueSearchResultFragment
                            }
                        />
                    ))}
            </div>
        </div>
    );
};
