import {FC} from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {useQuery} from '@apollo/client';
import clsx from 'clsx';
import {EditorsPickCards} from 'components/MarketingContents/EditorsPickCards';
import {FeaturedVenueCards} from 'components/MarketingContents/FeaturedVenueCards';
import {HomeCards} from 'components/MarketingContents/HomeCards';
import {NavigatorCards} from 'components/MarketingContents/NavigatorCards';
import Search from 'components/Search';
import {
    HomePageDocument,
    HomePageQuery,
    VenueSearchResultFragment,
} from 'gql/generated';
import useFadeIn from 'hooks/useFadeIn';
import useNavigateLocale from 'hooks/useNavigateLocale';
import useScrollToTop from 'hooks/useScrollToTop';
import {useLocale} from 'state/locale';
import {Locale} from 'types';
import {SearchValues} from 'types/search';
import {getVenueQueryFromValues} from 'utils/params';
import HomeVenueGroup from './HomeVenueGroup';
import styles from './styles.module.css';

const {headerShadow, heroImage} = styles;

// TODO: Refactor new arrivals into its own component/query and fully remove this FeaturedVenueIds implementation
const featuredVenueIds: {[key in Locale]: string[]} = {
    en: [],
    ja: [],
};

const HomePage: FC = () => {
    const fadeIn = useFadeIn();
    const {t} = useTranslation();
    const navigate = useNavigateLocale();
    const locale = useLocale();

    const {data} = useQuery<HomePageQuery>(HomePageDocument, {
        variables: {
            featuredVenueIds: featuredVenueIds[locale],
        },
    });

    useScrollToTop();

    const onSearch = (formData: SearchValues) => {
        navigate(`/restaurants${getVenueQueryFromValues(formData)}`);
    };

    return (
        <div>
            <div>
                <div
                    className={`mx-auto h-[400px] bg-cover bg-no-repeat sm:h-[280px] ${heroImage}`}
                >
                    <div className="h-full w-full dark:bg-grey-900/40">
                        <div className="site-container flex h-full items-center">
                            <div className="w-full sm:mt-1 lg:-mt-2 xl:mt-8">
                                <div className="sm:max-w-[85%] md:max-w-[75%] lg:max-w-[55%] xl:max-w-[48%]">
                                    <h1
                                        className={clsx(
                                            'text-2xl font-light text-white sm:text-2xl lg:leading-relaxed',
                                            headerShadow,
                                            fadeIn
                                        )}
                                    >
                                        <Trans i18nKey="home.makeReservationsThroughPocket" />
                                    </h1>
                                </div>
                                <Search
                                    className={clsx(
                                        'mt-4 sm:mt-6 xl:mt-6',
                                        fadeIn
                                    )}
                                    isHomePage={true}
                                    onSearch={onSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex flex-col gap-12 pt-5 sm:pt-10">
                <HomeCards />
                <NavigatorCards />
                <EditorsPickCards />
                {!!data?.newArrivals?.collection?.length && (
                    <HomeVenueGroup
                        name={t('home.newArrivals')}
                        venues={
                            data.newArrivals
                                .collection as VenueSearchResultFragment[]
                        }
                    />
                )}
                <FeaturedVenueCards />
            </div>
        </div>
    );
};

export default HomePage;
