import useBreakpoint from 'hooks/useBreakpoint';

export const TermsOfUsePage = () => {
    const isDesktop = useBreakpoint('sm');

    if (isDesktop) {
        return (
            <div className="prose prose-sm mb-6 flex min-h-[600px] max-w-none flex-col pt-6 dark:prose-dark md:prose sm:min-h-[900px] md:max-w-none md:px-0">
                <h1 className="shrink place-self-center sm:place-self-start">
                    ポケットコンシェルジュ利用規約(ユーザー向け)
                </h1>
                <object
                    className="grow"
                    data="https://d19uhz6wp79v31.cloudfront.net/JA_%E3%83%9D%E3%82%B1%E3%83%83%E3%83%88%E3%82%B3%E3%83%B3%E3%82%B7%E3%82%A7%E3%83%AB%E3%82%B8%E3%83%A5%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84%EF%BC%88%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%EF%BC%89.pdf#toolbar=1&navpanes=0&scrollbar=1"
                    height="100%"
                    type="application/pdf"
                    width="100%"
                >
                    <p>
                        このブラウザはインラインPDFをサポートしていません。PDFをダウンロードしてご覧ください：
                        <a href="https://d19uhz6wp79v31.cloudfront.net/JA_%E3%83%9D%E3%82%B1%E3%83%83%E3%83%88%E3%82%B3%E3%83%B3%E3%82%B7%E3%82%A7%E3%83%AB%E3%82%B8%E3%83%A5%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84%EF%BC%88%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%EF%BC%89.pdf">
                            PDFをダウンロード
                        </a>
                    </p>
                </object>
            </div>
        );
    }

    return (
        <div className="mx-4 flex flex-col items-center">
            PDFをダウンロードしてご覧ください：
            <a
                className="pt-4 text-xl"
                href="https://d19uhz6wp79v31.cloudfront.net/JA_%E3%83%9D%E3%82%B1%E3%83%83%E3%83%88%E3%82%B3%E3%83%B3%E3%82%B7%E3%82%A7%E3%83%AB%E3%82%B8%E3%83%A5%E5%88%A9%E7%94%A8%E8%A6%8F%E7%B4%84%EF%BC%88%E3%83%A6%E3%83%BC%E3%82%B6%E3%83%BC%EF%BC%89.pdf"
            >
                PDFをダウンロード
            </a>
        </div>
    );
};
