import {FC, useState} from 'react';
import {Link} from 'react-router-dom';
import clsx from 'clsx';
import {trackClick} from 'utils/analytics';
import transparentImg from '../transparent.webp';

export const HomeCardSkeleton: FC = () => {
    return (
        <figure className="skeleton border-venue-result flex aspect-4/3 select-none flex-col overflow-hidden rounded">
            <div className="w-full">
                <picture>
                    <img
                        alt="loading"
                        className="absolute left-0 top-0 h-full w-full object-cover object-center"
                        src={transparentImg}
                    />
                </picture>
            </div>
        </figure>
    );
};

type TitleProps = {
    name: string;
};

export const HomeCardTitle: FC<TitleProps> = ({name}) => {
    return (
        <div className="sm:site-container bg-body sticky inset-x-0 top-[3.25rem] z-40 flex items-start justify-between px-4 py-1 sm:static">
            <div>
                <h3 className="text-xl">{name}</h3>
            </div>
        </div>
    );
};

type MarketingHomeCardProps = {
    className?: string;
    imageFilename?: string | null;
    imageUrl: string;
    isExternal: boolean;
    link: string;
};

export const MarketingHomeCard: FC<MarketingHomeCardProps> = ({
    className = '',
    imageFilename,
    imageUrl,
    isExternal,
    link,
}) => {
    const [imageError, setImageError] = useState(false);
    const imageName = imageUrl.split('/')?.pop() || 'marketing-material';
    const assetName = link.split('/')?.pop() || 'marketing-material-home-card';

    if (imageError) {
        return null;
    }

    return (
        <Link
            aria-label={imageFilename || imageName}
            className={clsx(
                'border-venue-result flex aspect-4/3 select-none flex-col overflow-hidden rounded',
                className
            )}
            data-dd-action-name={imageFilename || assetName || imageName}
            onClick={() => trackClick(assetName || imageName)}
            rel={isExternal ? 'noopener noreferrer' : undefined}
            reloadDocument={true}
            target={isExternal ? '_blank' : undefined}
            to={link}
        >
            <figure className="relative">
                <picture>
                    <img
                        alt={imageFilename || imageName}
                        className={className}
                        loading="lazy"
                        onError={() => setImageError(true)}
                        src={imageUrl}
                    />
                </picture>
            </figure>
        </Link>
    );
};
