import {FC} from 'react';
import {Link as ReactRouterLink, LinkProps} from 'react-router-dom';
import {useLocale} from 'state/locale';

const Link: FC<LinkProps> = (props) => {
    const locale = useLocale();
    const {to} = props;

    // Some elements have exacting links where we do not want the English page to append that link
    const bypass = typeof to === 'string' && to.startsWith('https://');

    if (locale === 'ja' || bypass) {
        return <ReactRouterLink {...props} />;
    }

    return <ReactRouterLink {...props} to={`/${locale}${to}`} />;
};

export default Link;
