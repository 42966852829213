import {
    addDays,
    format,
    isAfter,
    isBefore,
    isValid,
    parseISO,
    set,
    subDays,
} from 'date-fns';
import {ja} from 'date-fns/locale';
import {utcToZonedTime, zonedTimeToUtc} from 'date-fns-tz';
import {Locale} from 'types';
import {SearchDateRange} from 'types/search';

const timeZone = 'Asia/Tokyo';

export const toJST = (date = new Date()) => utcToZonedTime(date, timeZone);

export const fromJST = (date = new Date()) => zonedTimeToUtc(date, timeZone);

export const setNoon = (date = toJST()): Date =>
    set(date, {
        hours: 12,
        milliseconds: 0,
        minutes: 0,
        seconds: 0,
    });

const CUTOFF: Date = set(toJST(), {
    hours: 14,
    milliseconds: 0,
    minutes: 45,
    seconds: 0,
});

export const isBeforeCutoff = () => isBefore(toJST(), CUTOFF);

export const isAfterSixPmDayBefore = (date: Date) => {
    const dayBefore = subDays(date, 1);
    const sixPmDayBefore = set(dayBefore, {
        hours: 18,
        milliseconds: 0,
        minutes: 0,
        seconds: 0,
    });

    return isAfter(toJST(), sixPmDayBefore);
};

export const getMinDate = (): Date =>
    isBeforeCutoff() ? setNoon() : addDays(setNoon(), 1);

export const getSearchDateRange = (date = toJST()): SearchDateRange => {
    const min = getMinDate();
    const max = addDays(min, 89);
    const current = setNoon(date);

    return {
        // prevent current from being outside range
        current: current > max ? max : current < min ? min : current,
        max,
        min,
    };
};

export const fromISO8601Date = (ymd: string, dateTime = setNoon()) => {
    const [year, month, day] = ymd.split('-').map(Number);

    return set(dateTime, {date: day, month: month - 1, year});
};

export const isExpired = (value: string, date = new Date()) => {
    const [month, year] = value.split('/').map(Number);

    if (month && year && !Number.isNaN(month) && !Number.isNaN(year)) {
        const fullYear = year > 99 ? year : Number(`20${year}`);

        return !(
            fullYear > date.getFullYear() ||
            (fullYear === date.getFullYear() && month >= date.getMonth() + 1)
        );
    }

    return false;
};

export const toFullDate = (date: Date, locale: Locale) =>
    locale === 'en'
        ? format(date, 'PPPP')
        : format(date, 'PPP（E）', {locale: ja});

export const toMY = (date = new Date()) => format(date, 'MM/yy');

export const toTime = (date: Date, locale: Locale) => {
    const jst = toJST(date);

    if (locale === 'en') {
        return format(jst, 'p');
    }
    const time = format(jst, 'p', {locale: ja});
    const hour = Number(time.split(':').shift());
    if (hour > 5) return time;

    return `${24 + hour}:${time.split(':').pop()}`;
};

export const toTime24 = (date: Date) => format(toJST(date), 'HH:mm');

export const toISO8601Date = (date = new Date()) => format(date, 'yyyy-MM-dd');

export const getDateString = (
    date: Date,
    locale: Locale,
    showFullDate: boolean
) => {
    if (locale === 'en') {
        return format(date, showFullDate ? 'PPPP' : 'PP');
    }

    return format(date, showFullDate ? 'PPP（E）' : 'PPP', {locale: ja});
};

const iso8601DateTimeRegex =
    /(\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d\.\d+([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2](?:\d:[0-5]){2}\d([+-][0-2]\d:[0-5]\d|Z))|(\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d([+-][0-2]\d:[0-5]\d|Z))/;

export const fromIOS8601DateTimeString = (value: string) => {
    if (iso8601DateTimeRegex.test(value)) {
        const parsedDate = parseISO(value);

        if (isValid(parsedDate)) {
            return parsedDate;
        }
    }

    return undefined;
};
